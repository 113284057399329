'use client';

import { useSession } from '@grimme/next-grimme-auth';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useRouter } from '@/lib/i18n/routing';

export const UnauthenticatedGuard: FC<PropsWithChildren> = ({ children }) => {
  const { status: sessionStatus } = useSession();
  const router = useRouter();

  useEffect(() => {
    // Redirect to home page;
    if (sessionStatus === 'authenticated') {
      router.push(`/home`);
    }
  }, [sessionStatus]);

  return children;
};
